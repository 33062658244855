<template>
    <RecordsComponent
        ref="jobRef"
        v-model:data="data"
        v-model:error="errors"
        v-model:archiveModal="archiveModal"
        :loading="isLoading"
        :tableData="tableData"
        showTable
        :id="currentId"
        :pageType="pageType"
        :template="template"
        :tableEndpoints="tableEndpoints"
        :isArchived="isArchived"
        @changeTable="updateTable"
        @add="addItem"
        @copy="copyAddress"
        @create="openCreate"
        @save="saveRecord"
        @reloadData="getRecord"
        @clickCol="clickCol"
        @createRecord="openCreateParent"
        @delete="openArchiveModal"
        @updateLoading="updateLoading"
        @acceptQuote="openAcceptQuoteModal"
    />
    <CreateNew
        startTab="certificate"
        v-model:visible="createModal.visible"
        v-model:data="createModal.data"
        :jobId="currentId"
    />
    <AcceptQuoteModal
        v-model:visible="acceptQuoteModal.visible"
        :loading="acceptQuoteModal.loading"
        @acceptQuote="confirmAcceptQuote"
    />
</template>

<script>
import { defineComponent, reactive, provide, ref, watch } from "vue";

import AcceptQuoteModal from "./overview/AcceptQuoteModal";
import RecordsComponent from "@/features/RecordsComponent";
import CreateNew from "@/widgets/CreateNew";
import { useRecord } from "@/shared/config";

const Jobs = defineComponent({
    name: "Jobs",
    props: {},
    components: { RecordsComponent, CreateNew, AcceptQuoteModal },
    setup() {
        const {
            data,
            pageType,
            currentId,
            errors,
            template,
            isLoading,
            isArchived,
            tableEndpoints,
            itemsByTypes,
            tableData,
            archiveModal,
            saveRecord,
            getRecord,
            updateLoading,
            openArchiveModal,
            clickCol,
            acceptQuote,
        } = useRecord("job", { value: "job_status_id" });

        const jobRef = ref();

        provide("itemsByTypes", itemsByTypes);

        const acceptQuoteModal = reactive({
            visible: false,
            loading: false,
        });

        const createModal = reactive({
            visible: false,
        });

        const openCreate = () => {
            createModal.visible = true;
        };

        const openAcceptQuoteModal = () => {
            acceptQuoteModal.visible = true;
        };

        const confirmAcceptQuote = async () => {
            acceptQuoteModal.loading = true;
            await acceptQuote();
            acceptQuoteModal.loading = false;
            acceptQuoteModal.visible = false;
            jobRef.value.reloadTable();
        };

        watch(
            itemsByTypes,
            ({ job_statuses }) => {
                if (
                    job_statuses.length > 0 &&
                    ((typeof data.value.job_status_id === "object" && !data.value.job_status_id?.title) ||
                        !data.value.job_status_id)
                ) {
                    data.value.job_status_id = job_statuses.find((el) => el.title === "Not selected").value;
                }
            },
            { once: true }
        );

        return {
            data,
            pageType,
            tableData,
            currentId,
            createModal,
            template,
            errors,
            tableEndpoints,
            isLoading,
            archiveModal,
            isArchived,
            acceptQuoteModal,
            jobRef,
            saveRecord,
            openCreate,
            getRecord,
            openArchiveModal,
            updateLoading,
            clickCol,
            openAcceptQuoteModal,
            acceptQuote,
            confirmAcceptQuote,
        };
    },
});
export default Jobs;
</script>
