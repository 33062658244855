<template>
    <RecordsComponent
        ref="recordRef"
        v-model:data="data"
        v-model:error="errors"
        v-model:archiveModal="archiveModal"
        v-model:loading="isLoading"
        :tableData="tableData"
        showTable
        :id="currentId"
        :pageType="pageType"
        :template="template"
        :tableEndpoints="tableEndpoints"
        :isArchived="isArchived"
        @updateLoading="updateLoading"
        @add="openModal"
        @copy="copyAddress"
        @save="saveRecord"
        @setAddress="setAddress"
        @changeParent="openChangeParent"
        @openArchive="openArchiveFromTable"
        @clickCol="clickCol"
        @delete="openDeleteModal"
        @reloadData="reloadData"
    />

    <RecordsModal
        v-model:data="modal.data"
        v-model:visible="modal.visible"
        :pageType="modal.pageType"
        :recordType="modal.recordType"
        :loading="modal.loading"
        @save="setApplianceRecord"
        @create="setApplianceRecord"
        @add="openModal"
        @cancel="closeModal"
        @delete="archiveAppliance"
    />
    <ChangeParentModal :id="currentId" ref="changeParentRef" />
</template>

<script>
import { defineComponent, provide, ref } from "vue";
import { useRecord } from "@/shared/config";
import { useAppliance } from "./config";

import RecordsComponent from "@/features/RecordsComponent";
import RecordsModal from "@/widgets/RecordsModal";
import ChangeParentModal from "./overview/ChangeParentModal";

const Property = defineComponent({
    name: "Property",
    props: {},
    components: {
        RecordsComponent,
        RecordsModal,
        ChangeParentModal,
    },
    setup() {
        const changeParentRef = ref();

        const {
            data,
            recordRef,
            pageType,
            currentId,
            errors,
            template,
            isLoading,
            isArchived,
            itemsByTypes,
            tableData,
            tableEndpoints,
            saveRecord,
            setAddress,
            getRecord,
            updateLoading: updateComponentLoading,
            copyAddress,
        } = useRecord("property");

        provide("itemsByTypes", itemsByTypes);

        const {
            modal,
            archiveModal,
            openModal,
            closeModal,
            openDeleteModal,
            archiveAppliance,
            setApplianceRecord,
            openArchiveFromTable,
            clickCol,
            closeArchiveModal,
            updateArchiveModalLoading,
        } = useAppliance(recordRef, data);

        const updateLoading = (data) => {
            updateComponentLoading(data);
            updateArchiveModalLoading(data);
        };

        const reloadData = () => {
            getRecord();
            closeArchiveModal();
            closeModal();
        };

        const openChangeParent = () => {
            changeParentRef.value.open();
        };
        return {
            data,
            recordRef,
            pageType,
            tableData,
            currentId,
            modal,
            template,
            isLoading,
            errors,
            archiveModal,
            tableEndpoints,
            isArchived,
            changeParentRef,
            openChangeParent,
            archiveAppliance,
            openModal,
            closeModal,
            copyAddress,
            setAddress,
            setApplianceRecord,
            openArchiveFromTable,
            clickCol,
            openDeleteModal,
            saveRecord,
            getRecord,
            updateLoading,
            reloadData,
        };
    },
});
export default Property;
</script>
<style lang="scss"></style>
